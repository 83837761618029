import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';

import { NumberField } from 'components/Forms/NumberField/NumberField';
import { MultiSelectField } from 'components/Forms/MultiSelectField/MultiSelectField';
import { companyRolesSelector, industryBranchesSelector } from 'ducks/constants/selectors';
import {
  isNewVisitFinished,
  isNewVisitFollowUp,
  isNewVisitWithEditingAccess,
  newVisitCompanyVisitSelector,
  newVisitWorkplaceOptionSelector,
  updateCompanyVisit,
  UpdateNewVisitCompanyVisit,
} from 'ducks/newVisit';
import { useAppDispatch } from 'store';
import { StyledRow } from 'containers/newVisit/OrdinaryVisit/Step3/styles';
import { InputlikeValue } from 'components/InputlikeValue/InputlikeValue';
import { UPDATE_TIME } from '../../constants';

interface CompanyDetailsSectionProps {
  selectedAddress: string;
  companyVisitId: string;
}

export const CompanyDetailsSection = ({
  selectedAddress,
  companyVisitId,
}: CompanyDetailsSectionProps) => {
  const industryBranches = useSelector(industryBranchesSelector).map((branch) => ({
    label: branch.name,
    value: String(branch.id),
  }));
  const companyVisit = useSelector(newVisitCompanyVisitSelector(companyVisitId))!;

  const workplaceOption = useSelector(newVisitWorkplaceOptionSelector);
  const companyRoles = useSelector(companyRolesSelector);
  const isVisitFinished = useSelector(isNewVisitFinished(companyVisitId));
  const isVisitFollowUp = useSelector(isNewVisitFollowUp(companyVisitId));
  const isVisitCanEdit = useSelector(isNewVisitWithEditingAccess(companyVisitId));
  const isDisabled = !isVisitCanEdit || (isVisitCanEdit && (isVisitFinished || isVisitFollowUp));

  const [employeesCount, setEmployeesCount] = useState(companyVisit.numberOfEmployees);
  const [companyRolesIds, setCompanyRolesIds] = useState(
    companyVisit.companyRoles.map((role) => role.value),
  );
  const [industryBranchIds, setIndustryBranchIds] = useState(companyVisit.industryBranchIds);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isHrrBranch = window.REACT_APP_ENV?.BRAND_ID === 'hrr';

  useEffect(() => {
    setEmployeesCount(companyVisit.numberOfEmployees);
    setCompanyRolesIds(companyVisit.companyRoles.map((role) => role.value));
    setIndustryBranchIds(companyVisit.industryBranchIds);
  }, [companyVisitId, setEmployeesCount, setCompanyRolesIds, setIndustryBranchIds]);

  const debouncedUpdateCompany = useCallback(
    debounce(
      (params: UpdateNewVisitCompanyVisit) => dispatch(updateCompanyVisit(params)),
      UPDATE_TIME,
    ),
    [companyVisitId],
  );

  return (
    <StyledRow data-testid='company-details-section'>
      {!isVisitCanEdit ? (
        <InputlikeValue
          label={t('table.columns.role')}
          value={
            companyVisit.companyRoles.length > 0
              ? companyVisit.companyRoles.map((role) => role.label).join(', ')
              : t('table.columns.no_role')
          }
        />
      ) : (
        <MultiSelectField
          buttonSize='small'
          buttonTestId='company-details-role-btn'
          input={{
            onChange: (companyRolesIds: string[]) => {
              debouncedUpdateCompany({
                companyRolesIds,
                companyVisitId,
                industryBranchIds,
                numberOfEmployees: employeesCount,
              });
              setCompanyRolesIds(companyRolesIds);
            },
            onBlur: () => {},
            onFocus: () => {},
            name: 'companyRoleIds',
            value: companyRolesIds,
          }}
          isDisabled={isDisabled}
          label={t('table.columns.role')}
          meta=''
          options={companyRoles}
          placeholder={`${t('forms.select_a')} ${t('table.columns.role')}`}
          required={companyVisit.requireCompanyRole}
          selectedItem={companyRolesIds}
        />
      )}
      <NumberField
        input={{
          onChange: (e) => {
            debouncedUpdateCompany({
              companyRolesIds,
              companyVisitId,
              industryBranchIds,
              numberOfEmployees: e.target.value,
            });
            setEmployeesCount(e.target.value);
          },
          onBlur: () => {},
          onFocus: () => {},
          name: 'employees',
          value: employeesCount,
        }}
        isDisabled={isDisabled}
        label={t('company_details.basic-data.number_of_employees')}
        meta=''
        required
        size='small'
      />

      {isHrrBranch && (
        <MultiSelectField
          buttonSize='small'
          buttonTestId='company-details-visited-as-btn'
          input={{
            onChange: (industryBranchIds) => {
              debouncedUpdateCompany({
                companyRolesIds,
                companyVisitId,
                industryBranchIds,
                numberOfEmployees: employeesCount,
              });
              setIndustryBranchIds(industryBranchIds);
            },
            onBlur: () => {},
            onFocus: () => {},
            name: 'industryBranchIds',
            value: industryBranchIds,
          }}
          isDisabled={isDisabled}
          label={t('new_visits.visited_as')}
          meta=''
          options={industryBranches}
          placeholder={t('forms.select_a')}
          required
          selectedItem={industryBranchIds}
        />
      )}

      {workplaceOption === 'SameAsCompany' && (
        <InputlikeValue label={t('new_visits.visit_address')} value={selectedAddress} />
      )}
    </StyledRow>
  );
};
